// COLORS
$primary_success: #59ac40;
$primary_blue: #2b388f;
$secondary_grey: #808080;

$white_background :#fff;
$info_icon_blue: #808080;
$black: #000000;

$accordion_background: #F0F4FF;

$default_icon_color:#0D99FF;
$default_text_color:#000000;

$alert-red: #df2a2a;
$light-red: #FFDDD5;
$light-green: #DFF7E9;
$light-blue: #9eaaf6;

$green-background: #81ba41;
$green-hover: #6f9f37;

//---gray shades----
$dailog-gray:#aba8a8;
$gray-00: #d3d3d3;
$gray-01: #c4c4c4;
$gray-02: #b6b6b6;
$gray-03: #989898;
$gray-04: rgb(65, 65, 65);
$gray-black-25: rgba(0, 0, 0, 0.25);

//--- tags tooltip ----
$box-background-light: rgb(212, 210, 210);
$box-shadow-dark: rgba(0, 0, 0, 0.1);

// FONTS
$default_font_family: 'Roboto';
$fallback-font:'sans-serif';
$default_font_size: 0.875;

// SPACING
$one_rem: 1rem;
$half_rem: 0.5rem;
.mt-01{margin-top: 0.1rem;}
.mt-02{margin-top: 0.2rem !important;}
.mt-03{margin-top: 0.3rem;}
.mt-04{margin-top: 0.4rem;}
.mt-05,.mt-half{margin-top: $half_rem !important;}
.mt-06{margin-top: 0.6rem;}
.mb-01{margin-bottom: 0.1rem;}
.mb-02{margin-bottom: 0.2rem;}
.mb-03{margin-bottom: 0.3rem;}
.mb-04{margin-bottom: 0.4rem;}
.mb-05,.mb-half{margin-bottom: $half_rem !important;}
.mb-06{margin-bottom: 0.6rem;}
.p{padding: $one_rem !important;}
.p-0{padding: 0 !important;}
.pr-0{padding-right: 0 !important;}
.pl-0{padding-left: 0 !important;}
.pb-0{padding-bottom: 0 !important;}
.pt-0 {padding-top: 0 !important;}
.p-half{padding: $half_rem !important;}
.pr-half{padding-right: $half_rem !important;}
.pl-half{padding-left: $half_rem !important;}
.pb-half{padding-bottom: $half_rem !important;}
.pt-half {padding-top: $half_rem !important;}

.m-0{margin: 0 !important;}
.mt-0 {margin-top: 0 !important;}
.mr-0{margin-right: 0 !important;}
.ml-0{margin-left: 0 !important;}
.mb-0{margin-bottom: 0 !important;}

.m{margin: $one_rem !important;}
.mt{margin-top: $one_rem !important;}
.ml{margin-left: $one_rem !important;}
.mr{margin-right: $one_rem !important;}
.mb{margin-bottom: $one_rem !important;}

.w-50, .w-half { width: 50% !important;}
.w-75 { width: 75% !important;}
.w-100, .w-full { width: 100% !important;}

// BREAKS POINT

$x-large: 1920px;
$large: 1536px;
$regular: 1280px;
$medium: 1024px;
$small: 768px;
$x-small: 600px;