body:has(.consolidated-contract-reports) #menu-contractTypeId {
    ul {
        li:nth-child(2) {
            border-bottom: 1px solid #ddd;
        }
    }
}
  
body:not(.pre-processing-exist) #menu-contractTypeId {
    ul {
        li:nth-child(2) {
            border-bottom: 0;
        }
    }
}