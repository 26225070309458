.assign-user-tab{
    .tableContainerWrap {
        min-height: 55vh;
    }
    .selection-row {
        display: flex;
        justify-content: space-between;
        margin: $one_rem;
      }
    .assign-unassign-count{
        display: flex;
        flex-direction: row;
        gap: 30px;
    }
    .selection-count{
        align-content: center;
    }
    .search-textfield{
        width:28rem;
    }
    .form-buttons{
        display: flex; 
        width: 100%;
        justify-content: flex-end;
    }
    .previous-button {
        width: 5.813rem;
        height: 2.4rem;
        margin-left: 0.65rem;
        font-weight: 400;
        font-size: 0.85rem;
        margin-right: 20px;
        color: $black;
      }
    .save-button {
        width: 5.813rem;
        height: 2.4rem;
        font-weight: normal;
        font-size: 0.85rem;
        border-width: 0.063rem;
        border-style: solid;
        border-color: $gray-02;
        font-weight: 400;
        margin-right: 10px;       
      }
        .user-info {
            .user-avatar {
                display: none;
            }
        }
}
.upload-button {
    justify-content: flex-end;
    margin-right: 40px;
  }