.createEmail {
  .generate-summary-only {
    font-size: 0.75rem;
    color: $secondary_grey;
  }
}
.email-view-version-popup {
  .MuiDialog-container {
    .MuiPaper-root {
      max-width: 830px !important;
      padding: 15px 10px;
      overflow-y: auto;
      max-height: calc(100% - 20px) !important;
      .dialogTitle {
        text-align: left;
        padding-left: 10px;
        margin-bottom: 10px;
      }
      .view-email-version-popup {
        fieldset {
          height: 200px !important;
          .rdw-editor-toolbar.toolbar-class {
            display: none;
          }
        }
      }
      .mid-popup-contant {
        width: calc(100% - $one_rem);
        padding: 10px 0 !important;
        border: 0;
      }
    }
  }
}
