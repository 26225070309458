.text-editor {
    resize: both;
    overflow: auto;
    display: flex;
    max-width: 100%;
    flex-direction: column;
    fieldset {
        border: 1px solid $gray-01;
        border-radius: 4px;
        flex: 1;
        &:hover {
            border: 1px solid #000;
        }
        legend {
            font-size: 11px;
            color: rgba(0, 0, 0, 0.6);
        }
        .wrapper-class,
        .editor-class {
            padding: 0;
            border: 0;
        }
        .editor-class {
            overflow: auto;
            min-height: 130px;
        }
        .toolbar-class {        
            border: 0;
            border-bottom: 1px solid #989898;
            border-radius: 0;
            padding-bottom: 10px;
        }
        .editor-actions {
            margin-top: 10px;
            display: grid;
            grid-auto-flow: column;
            justify-content: space-between;
        }
    }

    .text-color-error{
         color: "#d32f2f";
    }
}

.modal-content-preview {
    .modal-box {
        width: 800px;
        background-color: white;
        border-radius: 5px; 
        max-height: calc(100% - 20px);
        .modal-heading-preview {
            display: grid;    
            justify-content: space-between;
            grid-auto-flow: column;
            padding: 10px 15px;
            .close-modal-content-preview {
                color: black;
                cursor: pointer;
                margin-top: 3px;
            }
        }
        .modal-text-wrapper {
            max-width: 800px;
            overflow-y: auto;
            max-height: calc(100% - 20px);
            .text-editor {
                position: relative;
                padding: 0 15px 15px 15px;
                fieldset {
                    height: auto;
                }
                .wrapper-class {
                    border: 0;
                    padding: 0;
                }
                .copy-button {
                    position: absolute;
                    bottom: 20px;
                    right: 46px;
                    display: flex;
                    z-index: 1;
                    background: #fff;
                }
                .editor-class {
                    border: 0;
                    border-top: 0;
                }
            }
        }
    }

}