.assign-user-page{
    .tableContainerWrap {
        min-height: 75vh;
    }
    .selection-row {
        display: flex;
        justify-content: space-between;
      }
    .assign-unassign-count{
        display: flex;
        flex-direction: row;
        gap: 30px;
        white-space: nowrap;
        .reanalyzeIconWrap {
            margin-left: $half_rem;
        }
    }
    .selection-count{
        align-content: center;
    }
    .search-text{
        margin-right: 20px;
    }
    .search-textfield{
        width:28rem;
    }
    .form-buttons{
        display: flex; 
        width: 100%;
        justify-content: flex-end;
    }
    .previous-button {
        width: 5.813rem;
        height: 2.4rem;
        margin-left: 0.65rem;
        font-weight: 400;
        font-size: 0.85rem;
        margin-right: 20px;
        color: $black;
      }
    .save-button {
        width: 5.813rem;
        height: 2.4rem;
        font-weight: normal;
        font-size: 0.85rem;
        border-width: 0.063rem;
        border-style: solid;
        border-color: $gray-02;
        font-weight: 400;
        margin-right: 10px;       
      }

      .assign-user-page-search {
        width: 100%;
        padding: $one_rem;
        display: flex;
        justify-content: flex-end;
        .searchWrapper {
          width: 21rem;
        }
        .selectWrapper {
            width: 180px;
        }
    }

    .assign-user-title{
        display: flex;
        justify-content: space-between;
        margin-bottom: $one_rem;
        align-items: center;
        margin-top: auto;
        h1 {
            white-space: nowrap;
        }
    }

    .user-info {
        .user-avatar {
            display: none;
        }
    }
    .assgnUserSelectStatus {
        fieldset {
            legend {
                width: 73px;
            }
        }
    }
}

.upload-button {
    justify-content: flex-end;
    margin-right: 40px;
  }