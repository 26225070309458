.sales-training-list-page {
    .orderby-cell {
      min-width: 50px;
      max-width: 100px;
    }
    .promptname-cell {
      min-width: 150px;
      max-width: 200px;
    }
    .prompttype-cell {
        min-width: 100px;
        max-width: 120px;
      }
     .createdby-cell {
      min-width: 100px;
      max-width: 110px;      
     }
    
  }